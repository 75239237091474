.theme-light {
  --page-background-color: #ffffff;
  --background-color: #f5f5f5;

  --color-primary: #28334a;
  --color-info: #269cd7;
  --color-info-30: rgba(38, 156, 215, 0.3);
  --color-danger: #b7312c;
  --color-danger-30: rgba(183, 49, 44, 0.3);

  --text-color-primary: #4c4c4c;
  --text-color-highlight: #28334a;
  --text-color-header: #28334a;
  --text-color-inverse: #ffffff;

  --icon-color-primary: #28334a;
  --icon-color-inverse: #ffffff;

  --button-text-danger: #ffffff;

  --button-outline-border: #e5e5e5;

  --table-border-color: #b8b8b8;

  --tabs-background-primary: #28334a;

  --mdc-slider-inactive-track-color: var(--background-color);
  --mdc-slider-active-track-color: var(--color-info);
  --mdc-slider-handle-color: var(--text-color-inverse);
  --mdc-switch-unselected-handle-color: var(--text-color-inverse);
  --mdc-switch-selected-handle-color: var(--text-color-inverse);
  --mdc-switch-unselected-track-color: var(--background-color);
  --mat-switch-track-outline-color: var(--background-color);
  --mdc-switch-selected-track-color: var(--color-info);

  --mdc-slider-inactive-hover-track-color: var(--background-color);
  --mdc-slider-active-hover-track-color: var(--color-info);
  --mdc-slider-hover-handle-color: var(--text-color-inverse);
  --mdc-switch-unselected-hover-handle-color: var(--text-color-inverse);
  --mdc-switch-selected-hover-handle-color: var(--text-color-inverse);
  --mdc-switch-unselected-hover-track-color: var(--background-color);
  --mat-switch-hover-track-outline-color: var(--background-color);
  --mdc-switch-selected-hover-track-color: var(--color-info);

  --mdc-slider-inactive-focus-track-color: var(--background-color);
  --mdc-slider-active-focus-track-color: var(--color-info);
  --mdc-slider-focus-handle-color: var(--text-color-inverse);
  --mdc-switch-unselected-focus-handle-color: var(--text-color-inverse);
  --mdc-switch-selected-focus-handle-color: var(--text-color-inverse);
  --mdc-switch-unselected-focus-track-color: var(--background-color);
  --mat-switch-focus-track-outline-color: var(--background-color);
  --mdc-switch-selected-focus-track-color: var(--color-info);

  .mdc-slider__thumb-knob {
    border: 2px solid var(--button-outline-border) !important;
  }

  .mdc-switch__handle {
    border: 2px solid var(--button-outline-border) !important;
  }
}
