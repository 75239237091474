@use "theming";
@use "fonts-styles";

.mat-mdc-tab {
  flex-grow: 0 !important;
  border-radius: 10px 10px 0 0;

  height: 42px !important;
}

.mdc-tab {
  &__text-label {
    color: white !important;

    @extend .font-basic-16-semi-bold;
  }

  &--active {
    background-color: theming.$page-background-color !important;

    .mdc-tab__text-label {
      color: theming.$text-color-highlight !important;
    }
  }
}

.mdc-tab-indicator {
  display: none !important;
  border: none !important;
}

.mat-mdc-tab-label-container {
  padding: 30px 30px 0 !important;
  border-radius: 10px;
  border: none !important;

  background-color: theming.$tabs-background-primary;
}

.projects-tab {
  .mat-mdc-tab-label-container {
    position: relative;
    padding: 260px 30px 0 !important;

    background-color: rgba(40, 51, 74, 0.85);

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-blend-mode: multiply;
  }
}
