@use "theming";
@use "fonts-styles";
@use "partials/tabs";
@use "partials/dialogs";
@use "partials/toasts";
@use "partials/popup";
@use "partials/_material-styles";

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
body {
  background-color: theming.$page-background-color;

  @extend .font-family-basic !optional;

  overflow: hidden;
}

.blue-light-filter {
  position: fixed;
  inset: 0;
  background-color: #a48400;
  opacity: 0.1;
  pointer-events: none;
  mix-blend-mode: multiply;
  z-index: 100000000000000000000000;
}

svg-icon {
  display: flex;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  background-color: theming.$color-info;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

.mdc-tooltip__surface {
  background: theming.$text-color-highlight !important;
  color: theming.$text-color-inverse !important;
  @extend .font-basic-14-regular;
}
