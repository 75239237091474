@use "theming" as *;
@use "fonts-styles" as *;

.dialog-wide {
  .mat-mdc-dialog-surface {
    border-radius: 10px 0 0 10px !important;
    overflow: visible;
  }
}

.dialog-wide-event {
  .mat-mdc-dialog-surface {
    border-radius: 10px 0 0 10px !important;
    overflow: visible;
  }
}

.dialog-md {
  .mat-mdc-dialog-surface {
    border-radius: 10px !important;
  }
}

.close-dialog {
  position: absolute;
  left: -42px;
  top: 50%;
  transform: translateY(-50%);

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  background-color: $color-info;

  width: 42px;
  height: 42px;
  border-radius: 10px 0 0 10px;
}

.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;

  &__group {
    display: flex;
    align-items: center;
    gap: 30px;
  }
}

.pi-dialog {
  padding: 30px;
  display: flex;
  flex-direction: column;

  background-color: $page-background-color;

  gap: 10px;

  &__header {
    @extend .font-basic-24-semi-bold;
    color: $text-color-header;
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;

    &__description {
      @extend .font-basic-16-regular;
      color: $text-color-primary;
      margin-bottom: 10px;
    }

    &__option {
      display: flex;
      justify-content: space-between;

      @extend .font-basic-16-regular;

      &__timer {
        color: $color-danger;
      }

      &__resend {
        color: $color-primary;
        cursor: pointer;
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
