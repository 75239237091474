$font-weights: (
  light: 300,
  regular: 400,
  semi-bold: 600,
  bold: 700,
);

@each $name, $weight in $font-weights {
  .font-weight-#{$name} {
    font-weight: #{$weight};
  }
}
