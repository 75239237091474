@use "theming" as *;
@use "fonts-styles" as *;

.pi-popup {
  padding: 20px;
  background-color: $page-background-color;
  box-shadow: 0px 3px 20px #00000029;
  border-radius: 10px;

  &__list {
    display: flex;
    flex-direction: column;
    gap: 5px;

    &__item {
      @extend .font-basic-16-semi-bold;
      color: $text-color-highlight;

      cursor: pointer;

      &:hover {
        color: $color-info;
      }
    }
  }
}
