.theme-dark {
  --page-background-color: #19202E;
  --background-color: #28334A;

  --color-primary: #28334a;
  --color-info: #269cd7;
  --color-info-30: rgba(38, 156, 215, 0.3);
  --color-danger: #b7312c;
  --color-danger-30: rgba(183, 49, 44, 0.3);

  --text-color-primary: #B8B8B8;
  --text-color-highlight: #FFFFFF;
  --text-color-header: #269CD7;
  --text-color-inverse: #28334a;

  --icon-color-primary: #ffffff;
  --icon-color-inverse: #28334a;

  --button-text-danger: #ffffff;


  --button-outline-border: #e5e5e5;

  --table-border-color: #4c4c4c;

  --tabs-background-primary: #28334a;


  --mdc-slider-inactive-track-color: var(--background-color);
  --mdc-slider-active-track-color: var(--color-info);
  --mdc-slider-handle-color: var(--text-color-inverse);
  --mdc-switch-unselected-handle-color: var(--text-color-inverse);
  --mdc-switch-selected-handle-color: var(--text-color-inverse);
  --mdc-switch-unselected-track-color: var(--background-color);
  --mat-switch-track-outline-color: var(--background-color);
  --mdc-switch-selected-track-color: var(--color-info);
}
