@use "themes/light-theme";
@use "themes/dark-theme";
@use "themes/high-contrasts-theme";

$page-background-color: var(--page-background-color);
$background-color: var(--background-color);

$color-primary: var(--color-primary);
$color-info: var(--color-info);
$color-info-30: var(--color-info-30);
$color-danger: var(--color-danger);
$color-danger-30: var(--color-danger-30);

$text-color-primary: var(--text-color-primary);
$text-color-highlight: var(--text-color-highlight);
$text-color-header: var(--text-color-header);
$text-color-inverse: var(--text-color-inverse);

$icon-color-primary: var(--icon-color-primary);
$icon-color-inverse: var(--icon-color-inverse);

$color-button-danger-text: var(--button-text-danger);
$color-button-outline-border: var(--button-outline-border);

$table-border-color: var(--table-border-color);

$tabs-background-primary: var(--tabs-background-primary );
