:root {
  --base-font: 10px;
}

$font-sizes: (
  8: 0.8,
  10: 1,
  12: 1.2,
  14: 1.4,
  16: 1.6,
  18: 1.8,
  20: 2,
  22: 2.2,
  24: 2.4,
  26: 2.6,
  28: 2.8,
  30: 3,
  34: 3.4,
  36: 3.6,
  46: 4.6,
);

@each $name, $multiplier in $font-sizes {
  .font-#{$name} {
    font-size: calc(var(--base-font) * #{$multiplier});
  }
}
