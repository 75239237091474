.theme-high-contrasts {
  --page-background-color: #000000;
  --background-color: #000000;

  --color-primary: #000000;
  --color-info: #ffff00;
  --color-info-30: rgba(255, 255, 0, 0.3);
  --color-danger: #1aebff;
  --color-danger-30: rgba(26, 235, 255, 0.3);

  --text-color-primary: #ffffff;
  --text-color-highlight: #ffff00;
  --text-color-header: #ffff00;
  --text-color-inverse: #000000;

  --icon-color-primary: #28334a;
  --icon-color-inverse: #ffffff;

  --button-text-danger: #000000;

  --button-outline-border: #e5e5e5;

  --table-border-color: #ffffff;

  --tabs-background-primary: #4c4c4c;

  --mdc-slider-inactive-track-color: var(--background-color);
  --mdc-slider-active-track-color: var(--color-info);
  --mdc-slider-handle-color: var(--text-color-inverse);
  --mdc-switch-unselected-handle-color: var(--text-color-inverse);
  --mdc-switch-selected-handle-color: var(--text-color-inverse);
  --mdc-switch-unselected-track-color: var(--background-color);
  --mat-switch-track-outline-color: var(--background-color);
  --mdc-switch-selected-track-color: var(--color-info);
}
