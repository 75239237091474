@use "fonts/font-sizes";
@use "fonts/font-weights";
@use "fonts/font-families";

@forward "fonts/font-sizes";
@forward "fonts/font-weights";
@forward "fonts/font-families";

@each $family-name, $family in font-families.$font-families {
  @each $size-name, $size in font-sizes.$font-sizes {
    @each $weight-name, $weight in font-weights.$font-weights {
      .font-#{$family-name}-#{$size-name}-#{$weight-name} {
        font-family: #{$family};
        font-size: calc(var(--base-font) * #{$size});
        font-weight: #{$weight};
      }
    }
  }
}
