@use "fonts-styles" as *;

:root {
  --mdc-slider-inactive-track-height: 18px;
  --mdc-slider-inactive-track-shape: 9px;
  --mdc-slider-active-track-shape: 9px;
  --mdc-slider-active-track-height: 18px;
  --mdc-switch-track-width: 40px;

  --mdc-slider-handle-width: 22px;
  --mdc-slider-handle-height: 22px;
  --mdc-switch-track-height: 22px;
  --mat-switch-unselected-handle-size: 22px;
  --mat-switch-selected-handle-size: 22px;
  --mdc-switch-handle-width: 22px;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-selected-handle-horizontal-margin: 0;
}

.mdc-label {
  @extend .font-basic-16-regular;
}
